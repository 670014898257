import React from "react";
import HelmetWrapper from "../components/helmet-wrapper";
import Layout from "../components/layout";
import CountupClock from "../components/since/countup-clock";

const YearTen = ({ location }) => {
  return (
    <Layout location={location} killPadding={true} killFooter={true}>
      <HelmetWrapper pageTitle="Year Ten" />
      <div className="bg-theme2-bg-1 flex flex-col min-h-1/3 justify-end p-4 pb-20">
        <div className="font-serif-2 text-xl text-theme2-primary md:w-[640px] md:mx-auto md:text-2xl md:mt-20">
          <div className="mb-3">
            On December 12 of this year, we will have been married for one
            decade.
          </div>
          <div className="mb-3">
            We want to celebrate that, with as many of our loved ones as we can.
            We have a new story to tell, new friends to share it with, and this
            time we’ll have dancing.
          </div>
          <div className="mb-3">
            We’d really love for you to join us. We’re still working on the
            details (and the script), but consider this a save-the-date.
          </div>
        </div>
        <div className="my-20">
          <div className="w-fit mx-auto min-h-[252px] md:min-h-[528px] lg:min-h-[352px] grid grid-rows-3 lg:grid-rows-2 grid-cols-2 lg:grid-cols-3">
            <CountupClock />
          </div>
        </div>
        <div className="md:w-[640px] md:mx-auto mb-4">
          <div className="font-sans font-semibold text-4xl text-theme2-primary mb-2">
            Ten Years Later: A&nbsp;Celebration
          </div>
          <div className="font-serif-2 text-theme2-primary text-lg">
            December 11–14 | Denver, Colorado
          </div>
        </div>
        <div className="md:w-[640px] md:mx-auto mb-4">
          <div className="font-sans uppercase text-theme2-primary text-xs font-semibold tracking-wide py-4">
            Tentative Schedule
          </div>
          <ScheduleDay
            date="Wed Dec 11"
            items={[
              {
                type: "Pre Show",
                title: "The Marriage of Ivan and Livvy (A Reprise)",
              },
            ]}
          />
          <ScheduleDay
            date="Thu Dec 12"
            items={[
              {
                type: "Main Show",
                title: "Ten Years Later",
              },
              {
                type: "Party",
                title: "Dance till Eleven",
              },
            ]}
          />
          <ScheduleDay
            date="Fri Dec 13"
            items={[
              {
                type: "Hang",
                title: "Assorted activities in Denver",
              },
              {
                type: "Meal",
                title: "Sabbath Dinner",
              },
            ]}
          />
          <ScheduleDay
            date="Sat Dec 14"
            items={[
              {
                type: "Meal",
                title: "Brunch",
              },
              {
                type: "Hang",
                title: "Assorted activities in Denver",
              },
              {
                type: "Show",
                title: "PowerPoint Party No. ??",
              },
            ]}
          />
        </div>
        <div className="font-serif-2 text-lg text-theme2-primary md:w-[640px] md:mx-auto mt-20">
          <div className="md:w-96">
            Full invitation to follow. No need to RSVP now unless you already
            know you can’t make&nbsp;it.
          </div>
        </div>
        {/* <div className="md:w-[640px] md:mx-auto mb-4 flex justify-around py-20">
          <div className="bg-white text-sm uppercase font-semibold tracking-wide text-gray-700 py-2 px-4 rounded-full cursor-pointer">
            <a href="sms:+12694495555?body=I%20want%20to%20come!">
              I want to come!
            </a>
          </div>
          <div className="bg-white text-sm uppercase font-semibold tracking-wide text-gray-700 py-2 px-4 rounded-full cursor-pointer">
            <a href="sms:+12694495555?body=I%20can't%20make%20it">
              I can't make it
            </a>
          </div>
        </div> */}
      </div>
    </Layout>
  );
};

export default YearTen;

const ScheduleDay = ({ date, items }) => {
  return (
    <div className="py-4 border-t last:border-b border-theme2-bg-3 flex flex-col md:flex-row gap-4">
      <div className="w-32 text-theme2-secondary text-xs uppercase font-semibold tracking-wide">
        {date}
      </div>
      <div className="flex flex-col gap-4">
        {items.map((item, i) => {
          return (
            <div key={`scheduled-item-${i}`} className="">
              <div className="text-theme2-accent text-xs uppercase font-semibold tracking-wide">
                {item.type}
              </div>
              <div className="font-serif-2 text-2xl text-theme2-primary leading-7">
                {item.title}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
