import React, { useEffect, useState } from "react";
import { getTimeBetween } from "../../utils";
import TimeElement from "./time-element";

const WEDDING_TIMESTAMP = new Date("2014-12-12T15:33:30-05:00").getTime();
// const LATER_TIMESTAMP = new Date("2023-12-12T13:33:30-07:00").getTime();

const CountupClock = () => {
  const [diff, setDiff] = useState(null);

  useEffect(() => {
    const timer = setInterval(() => {
      const newDiff = getTimeBetween(WEDDING_TIMESTAMP, Date.now());
      setDiff(newDiff);
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    diff && (
      <>
        <TimeElement time={diff.y} label="Y" />
        <TimeElement time={diff.mo} label="M" />
        <TimeElement time={diff.d} label="D" />
        <TimeElement time={diff.h} label="H" />
        <TimeElement time={diff.m} label="M" />
        <TimeElement time={diff.s} label="S" />
      </>
    )
  );
};

export default CountupClock;
