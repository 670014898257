import React from "react";

const TimeElement = ({ time, label }) => {
  return (
    <div className="border-l border-theme2-bg-3 font-mono inline-flex m-3 md:m-6 px-6 md:px-12">
      <div className="text-6xl md:text-9xl text-theme2-primary">{time}</div>
      <div className="text-base md:text-2xl text-theme2-secondary mt-1 md:mt-3 ml-1 md:ml-2">
        {label}
      </div>
    </div>
  );
};

export default TimeElement;
